<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.orders.text')"
    />

    <b-tabs
      content-class="p-4"
      class="pt-2"
      nav-wrapper-class="nav-item"
      nav-class="justify-content-start nav-tabs-custom"
    >
      <b-tab title="General" active>
        <div class="container">
          <!-- Filters -->
          <div class="row mb-4">
            <div class="col-md-4">
              <select v-model="selectedArea" class="form-control">
                <option value="">Select Area</option>
                <option v-for="area in areas" :key="area.id" :value="area.id">
                  {{ area.name }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <input type="date" v-model="startDate" class="form-control" />
            </div>
            <div class="col-md-3">
              <input type="date" v-model="endDate" class="form-control" />
            </div>
            <div class="col-md-2">
              <button
                class="btn btn-primary btn-block"
                @click="fetchCustomerDetails"
              >
                Filter
              </button>
            </div>
          </div>

          <!-- Title -->
          <h1 class="text-center mb-4">Dashboard View</h1>

          <!-- Lead Cards -->
          <div class="row" id="LeadcardContainer">
            <div v-for="lead in leads" :key="lead.id" class="col-md-4">
              <div class="card shadow-sm">
                <div class="card-body">
                  <h5 class="card-title">{{ lead.full_name }}</h5>
                  <p class="card-text">Count: {{ lead.lead }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Order Status Cards -->
          <div class="row">
            <div
              v-if="orderStatuses.length > 0"
              class="row"
              id="orderStatusCardContainer"
            >
              <div
                v-for="status in orderStatuses"
                :key="status"
                class="col-md-4"
              >
                <div class="card shadow-sm">
                  <div class="card-body">
                    <h5 class="card-title">{{ status.Status }}</h5>
                    <p class="card-text">Count : {{ status.Count }}</p>
                  </div>
                </div>
              </div>
            </div>
            <h2 class="text-center mb-4">Cancellation Reasons</h2>
            <div class="row" id="cancelReasonCardContainer">
              <div
                v-for="reason in cancellationReasons"
                :key="reason.cancellation_reason"
                class="col-md-4"
              >
                <div class="card shadow-sm">
                  <div class="card-body">
                    <h5 class="card-title">{{ reason.cancellation_reason }}</h5>
                    <p class="card-text">
                      Count of Customers: {{ reason.count_customers }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab title="District base">
        <cityBasedReportVue />
      </b-tab>
      <b-tab title="Daily Report">
        <dailyReport />
      </b-tab>
    </b-tabs>
  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import cityBasedReportVue from "./cityBasedReport.vue";
import dailyReport from "./dailyReport.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    cityBasedReportVue,
    Layout,
    PageHeader,
    dailyReport,
  },
  data() {
    return {
      areas: [],
      leads: [],
      orderStatuses: [],
      cancellationReasons: [],
      selectedArea: "",
      startDate: "",
      endDate: "",
    };
  },
  methods: {
    fetchData() {
      this.http.get("orders/dashboard").then((response) => {
        console.log(response);
        // console.log(102,response.data)
        // this.orderStatuses = response.data.filter(
        //   (item) => item.procedural_description !== "Cancelled"
        // );
        // this.cancellationReasons = response.data.filter(
        //   (item) => item.procedural_description === "Cancelled"
        // );
      });
    },

    getOrders() {
      this.http.get("orders/dashboard").then((res) => {
        if (res.status) {
          this.orderStatuses = res.data.filter(
            (item) => item.procedural_description !== "Cancelled"
          );
        }
      });
    },
    fetchCustomerDetails() {
      this.http
        .post("orders/customer-details", {
          area_name: this.selectedArea,
          start_date: this.startDate,
          end_date: this.endDate,
        })
        .then((response) => {
          this.leads = [response.data];
        })
        .catch((error) => {
          console.error("Error fetching customer details:", error);
        });
    },

    fetchAreas() {
      this.http
        .get("areas")
        .then((response) => {
          this.areas = response.data;
        })
        .catch((error) => {
          console.error("Error fetching areas:", error);
        });
    },
  },
  created() {
    this.getOrders();
    this.fetchAreas();
  },
};
</script>

<style scoped>
.container {
  margin-top: 50px;
}

.card {
  margin-bottom: 20px;
  border-radius: 15px;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-text {
  font-size: 1.2rem;
}
</style>
