<template>
  <div class="container mt-5">
    <h2 class="mb-4">Daily Report</h2>
    <div class="row mb-3">
      <div class="col-2">
        <button class="btn btn-primary" @click="exportToExcel">
          <i class="fas fa-file-excel"></i> Export to Excel
        </button>
      </div>
      <div class="col-4">
        <button class="btn btn-danger ml-2" @click="exportToPDF">
          <i class="fas fa-file-pdf"></i> Export to PDF
        </button>
      </div>
    </div>
    <table
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      class="table table-striped table-bordered"
      ref="table"
    >
      <thead class="thead-dark">
        <tr>
          <th>City Name</th>
          <th>Delivery Man Name</th>
          <th>Daily Utilization</th>
          <th>Total Confirmations</th>
          <th>Total Postponements</th>
          <th>Total Actual</th>
          <th>Total Receipts</th>
          <th>Total Weight</th>
          <th>Total Weight (July)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>{{ item.city_name }}</td>
          <td>{{ item.delivery_man_name }}</td>
          <td>{{ item.daily_utilization }}</td>
          <td>{{ item.total_confirmations }}</td>
          <td>{{ item.total_postponements }}</td>
          <td>{{ item.total_actual }}</td>
          <td>{{ item.total_receipts }}</td>
          <td>{{ item.total_weight }}</td>
          <td>{{ item.total_weight_july }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  data() {
    return {
      data: [], // Initialize the data array
      base64Font: "", // To store base64 font data
      cityNames: [],
    };
  },
  async mounted() {
    await this.fetchData();
    await this.loadFont();
  },

  methods: {
    async loadFont() {
      try {
        const response = await fetch("/Tajawal-Bold.ttf");
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          this.base64Font = reader.result.split(",")[1];
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error loading font:", error);
      }
    },
    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Daily Report");
      XLSX.writeFile(wb, "daily_report.xlsx");
    },
    exportToPDF() {
      const doc = new jsPDF();

      // Add the custom font
      if (this.base64Font) {
        doc.addFileToVFS("Tajawal-Bold.ttf", this.base64Font);
        doc.addFont("Tajawal-Bold.ttf", "NotoSansArabic", "normal");
        doc.setFont("NotoSansArabic");
      }

      // Prepare table data
      const tableData = this.data.map((item) => [
        item.city_name,
        item.delivery_man_name,
        item.daily_utilization,
        item.total_confirmations,
        item.total_postponements,
        item.total_actual,
        item.total_receipts,
        item.total_weight,
        item.total_weight_july,
      ]);

      // Add autoTable
      doc.autoTable({
        head: [
          [
            "City Name",
            "Delivery Man Name",
            "Daily Utilization",
            "Total Confirmations",
            "Total Postponements",
            "Total Actual",
            "Total Receipts",
            "Total Weight",
            "Total Weight (July)",
          ],
        ],
        body: tableData,
        styles: {
          font: "NotoSansArabic",
          //   fontSize: 12,
          with: "100%",
          direction: "rtl",
        },
      });

      doc.save("daily_report.pdf");
    },
    async fetchData() {
      try {
        await this.http.get("orders/GenerateDailyReport").then((res) => {
          if (res.status) {
            this.data = res.data; // Assign the fetched data to the data array
            this.data.forEach((item) => {
              this.cityNames.push({ name: item.city_name });
            });

            this.cityNames = new Set(this.cityNames);
            console.log(this.cityNames);

            // for (let i = 0; i < this.data.length; i++) {
            //   for (let j = 0; j < this.cityNames.length; j++) {
            //     if (
            //       i == 0 &&
            //       this.cityNames[j].name == this.data[i].city_name
            //     ) {
            //       console.log("not");
            //     } else {
            //       this.data[i].city_name = "---";
            //     }
            //   }
            // }
          }
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>
<style scoped >
.table {
  width: 100%;
  border-collapse: collapse;
  animation: fadeIn 1s ease-in-out;
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
}

.table th {
  background-color: #343a40;
  color: #fff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.btn {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.btn i {
  margin-right: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
