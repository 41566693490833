<template>
  <div class="report-dashboard container mt-5">
    <div class="row">
      <!-- Cards for each district -->
      <div class="col-md-12 mb-4">
        <div class="accordion" id="reportAccordion">
          <div v-for="(report, index) in reportsByDistrict" :key="index">
            <div class="card mb-3">
              <div class="card-header dark-header" :id="'heading' + index">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link text-white"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapse' + index"
                    aria-expanded="true"
                    :aria-controls="'collapse' + index"
                  >
                    District: {{ report.district_id }}
                  </button>
                </h5>
              </div>
              <div
                :id="'collapse' + index"
                class="collapse show"
                :aria-labelledby="'heading' + index"
                data-parent="#reportAccordion"
              >
                <div class="card-body">
                  <!-- Displaying reports inline -->
                  <div class="row">
                    <div
                      v-for="item in report.items"
                      :key="item.report_type"
                      class="col-md-4 mb-3"
                    >
                      <div
                        :class="
                          'card text-white bg-' + item.status_color + ' h-100'
                        "
                      >
                        <div class="card-body">
                          <h5 class="card-title">{{ item.report_type }}</h5>
                          <p class="card-text">
                            Count: {{ item.report_count }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reportsByDistrict: [],
      loading: true,
    };
  },
  created() {
    this.fetchReports();
  },
  methods: {
    async fetchReports() {
      try {
        this.http.get("orders/reports/district").then((res) => {
          // Transform response data to a more structured format
          if (res.status) {
            this.reportsByDistrict = this.transformData(res.data);
          }
        });
      } catch (error) {
        console.error("Error fetching reports:", error);
      } finally {
        this.loading = false;
      }
    },
    transformData(data) {
      const groupedReports = {};

      data.forEach((item) => {
        if (!groupedReports[item.district_id]) {
          groupedReports[item.district_id] = {
            district_id: item.district_id,
            items: [],
          };
        }
        groupedReports[item.district_id].items.push({
          report_type: item.report_type,
          report_count: item.report_count,
          status_color: item.status_color || "primary",
        });
      });

      return Object.values(groupedReports);
    },
  },
};
</script>

<style scoped>
.report-dashboard {
  padding: 20px;
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 1.25rem;
}

.bg-warning {
  background-color: #ffc107;
}

.bg-info {
  background-color: #17a2b8;
}

.bg-secondary {
  background-color: #6c757d;
}

.bg-dark {
  background-color: #343a40;
}

.bg-danger {
  background-color: #dc3545;
}

.bg-success {
  background-color: #28a745;
}

.dark-header {
  background-color: #343a40; /* Dark background color */
  color: #ffffff; /* White text color */
}
</style>
